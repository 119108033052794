.searchbar fieldset {
  border: none;
}

.searchbar .MuiInputBase-root {
  background: #474747;
  border-radius: 24px;
  height: 48px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 4px 16px 4px 12px !important;
}

.searchbar button:not(.MuiAutocomplete-clearIndicator) {
  background: #353535;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 0px;
  color: #e3e3e3;
  min-width: 120px;
  margin-left: 0px;
}

.searchbar {
  flex: 5;
  width: 100%;
  max-width: 420px;
  justify-content: end;
}

.searchbar .MuiAutocomplete-clearIndicator {
  color: #e3e3e3;
  background: #353535;
}

.searchbar .MuiAutocomplete-endAdornment {
  left: 16px;
  right: unset;
}

.searchbar input {
  width: 100%;
  padding-right: 24px;
}

.hero-section {
  background-image: url(../../public/assets/algemeen/images/samir-homepage.png);
  height: clamp(360px, 40vh, 540px);
  background-position: 75% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: hsl(0deg 0% 0% / 38%);
  border-bottom: solid 1px hsl(0deg 0% 100% / 12%);
}

.hero-text-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  color: #e3e3e3;
}

@media screen and (min-width: 1920px) {
  .hero-section {
    background-position: 50% 10%;
    background-size: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .hero-section h1 {
    max-width: 20ch;
  }
}

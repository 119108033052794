.snapitem {
  align-items: center;
}

.yarl__root.captions-background-gradient::before {
  width: 100%;
  position: absolute;
  background: linear-gradient(90deg, rgb(0 31 255 / 0%) 50%, rgb(0 0 0) 90%);
  content: "";
  right: calc(50% - 100vw/2 + var(--yarl__swipe_offset));
  height: 63px;
  z-index: 1;
}

.yarl__toolbar {
  z-index: 1;
}
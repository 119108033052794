:not(:defined)>* {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

model-viewer {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /*overflow-y: auto;
  -webkit-overflow-scrolling: touch;*/
}


.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  background-color: rgba(255, 255, 255, 0.9);
  width: 0%;
  height: 100%;
  border-radius: 25px;
  float: left;
  transition: width 0.3s;
}

#ar-button {
  padding: 16px;
  height: 56px;
  width: 56px;
  border-radius: 28px;

  pointer-events: auto;
  position: absolute;
  top: 16px;
  right: 16px;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }

  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(-100px);
  }
}

model-viewer>#ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 60px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status="session-started"]>#ar-prompt {
  display: block;
}

model-viewer>#ar-prompt>img {
  animation: circle 4s linear infinite;
}

model-viewer::part(default-exit-webxr-ar-button) {
  left: 16px;
  right: unset;
}

model-viewer[ar-status="not-presenting"] > #ar-info-button {
  display: none;
}
.Puzzle {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.board {
  position: relative;
  padding: 0;
}

.tile {
  position: absolute;
  list-style: none;
  display: grid;
  place-items: center;
  font-size: 20px;
}
.rhap_container {
   /* background-color: #2E2E2E;
    background: linear-gradient(0deg, #282828, #2e2e2e);
    */
    padding: 24px 24px 16px 24px;
    border-radius: 12px;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%);
}

.rhap_time {
    color: #C7C7C7;
}

.rhap_time {
    font-size: 13px;
    letter-spacing: 0.05em;
}

.rhap_progress-container {
    margin: 0 calc(12px + 1%);
    height: 24px;
}

.rhap_progress-bar {
    height: 4px;
    background-color: #616161;
}

.rhap_download-progress {
    background-color: rgba(255,255,255,.12);
}


.rhap_progress-indicator {
    /*background-color: #F0C18C; */
    height: 16px;
    width: 16px;
    top: -6px;
    box-shadow: rgba(0,0,0,.16) 0 0 5px;
}



.rhap_play-pause-button {
   /* color: #F0C18C; */
    height: 64px;
    width: 64px;
    font-size: 64px;
}


/*
.rhap_progress-filled {
    background-color: #F0C18C;
}
*/

.rhap_current-time, .rhap_total-time {
    width: 40px;
}

.rhap_total-time {
    text-align: right;
}

.rhap_main-controls {
    width: 100%;
}

.rhap_volume-controls {
    display: none !important;
}

.rhap_volume-button {
    flex: 0 0 24px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.rhap_main-controls-button {
    margin: 0px 12px;
}